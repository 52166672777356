import styled from 'styled-components'
import { Logo } from './Logo'
const AppLoadingSplashContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
`

export function AppLoadingSplash(): JSX.Element {
  return (
    <AppLoadingSplashContainer>
      <Logo />
    </AppLoadingSplashContainer>
  )
}
