import { CopyIcon } from './Icons'
import styled from 'styled-components'
import { Tooltip } from './Tooltip'
import { Code } from './Typography'
import { VerticalDivider } from './VerticalDivider'

const CodeButtonStyles = styled.button`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
  border-color: ${({ theme }) => theme.colors.border};
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 1px;
  background-color: ${({ theme }) => theme.colors.background};
  gap: 4px;
`

export const CodeButton = ({
  snippet,
  text,
}: {
  snippet: string
  text?: string
}): JSX.Element => {
  return (
    <Tooltip message="Click to Copy">
      <CodeButtonStyles onClick={() => navigator.clipboard.writeText(snippet)}>
        <Code flex-grow={1} flex-shrink={1}>
          {text || snippet}
        </Code>
        <VerticalDivider color={'#666666'} flex-grow={1} flex-shrink={1} />
        <CopyIcon flex-grow={1} flex-shrink={1} />
      </CodeButtonStyles>
    </Tooltip>
  )
}
