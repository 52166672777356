import styled from 'styled-components'

interface TypographyProps {
  readonly $bold?: boolean
  readonly $error?: boolean
  readonly $warning?: boolean
  readonly $secondary?: boolean
  readonly $isCurrentUser?: boolean
}

export const Text = styled.p<TypographyProps>`
  color: ${({ theme, $error, $warning }) => {
    if ($error) {
      return theme.colors.font.error
    } else if ($warning) {
      return theme.colors.font.warning
    } else {
      return theme.colors.font.primary
    }
  }};
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')};
`

export const Heading = styled.h1<TypographyProps>`
  color: ${({ theme }) => theme.colors.font.primary};
  text-wrap: wrap;
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')};
`
export const SubHeading = styled.h2<TypographyProps>`
  color: ${({ theme }) => theme.colors.font.primary};
  text-wrap: wrap;
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')};
`
export const Code = styled.code<TypographyProps>`
  color: ${({ theme, $secondary }) => {
    if ($secondary) {
      return theme.colors.font.secondary
    } else {
      return theme.colors.font.primary
    }
  }};
  font-family: monospace;
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')};
`

export const InlineText = styled.span`
  display: flex;
  gap: 0.25rem;
`

export const Username = styled.p<TypographyProps>`
  color: ${({ theme }) => theme.colors.font.primary};
  display: inline;
  background: ${({ theme, $isCurrentUser }) =>
    $isCurrentUser ? theme.colors.font.highlight : 'transparent'};
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 30px;
`
