import styled from 'styled-components'

export const Main = styled.main`
  grid-area: main;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding-left: 1vw;
  padding-right: 1vw;
`

export const MainMaxWidth = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  height: 100%;
  width: 100%;
  max-width: 650px;
`
