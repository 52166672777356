import { useEffect, useState } from 'react'
import { Input } from '.'

export interface DebounceInputProps {
  value: string | number | readonly string[] | undefined
  onChange: (value: string | number | readonly string[] | undefined) => void
  debounce?: number
  After?: JSX.Element
}

export const DebounceInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: DebounceInputProps): JSX.Element => {
  const [value, setValue] = useState<
    string | number | readonly string[] | undefined
  >(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Input
      {...props}
      value={value}
      onChange={(event) => setValue(event.target.value)}
    />
  )
}
