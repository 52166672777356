import Link, { LinkProps } from 'next/link'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

const Text = styled.span`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
  padding: 0.5rem;

  display: inline-block;
  border-radius: 4px;

  color: ${({ theme }) => theme.colors.button.color};
  background-color: ${({ theme }) => theme.colors.button.background};
  border-color: ${({ theme }) => theme.colors.button.border};
  border-width: 1px;
  &:hover {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.button.hoverColor};
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.button.hoverBorder};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.buttonDisabled.color};
    background-color: ${({ theme }) => theme.colors.buttonDisabled.background};
    border-color: ${({ theme }) => theme.colors.buttonDisabled.border};
  }
  cursor: pointer;
`

type BackButtonProps = PropsWithChildren<LinkProps>

export const BackButton = (props: BackButtonProps): JSX.Element => {
  const { children, ...attributes } = props

  return (
    <Link {...attributes}>
      <Text>← {children}</Text>
    </Link>
  )
}
