import { PropsWithChildren } from 'react'
import { Button } from './Button'

type StatefulButtonProps = PropsWithChildren<{
  mode?: {
    disabled?: boolean
    loading?: boolean
  }
  onClick: () => void
}>

export const StatefulButton = (props: StatefulButtonProps): JSX.Element => {
  const { mode, onClick, children } = props
  const disabled = mode?.disabled ?? false
  const loading = mode?.loading ?? false

  return (
    <Button
      onClick={(event) => {
        event.preventDefault()
        onClick()
      }}
      disabled={disabled || loading}
    >
      {children}
      {loading ? '...' : null}
    </Button>
  )
}
