import { useEffect, useState } from 'react'

export function useUrls() {
  const [urls, setUrls] = useState<Record<string, string>>({
    AUTH_URL:
      process.env.NEXT_PUBLIC_AUTH_URL || 'https://auth.dev.tenstorrent.com',
    CLOUD_URL:
      process.env.NEXT_PUBLIC_CLOUD_URL || 'https://cloud.dev.tenstorrent.com',
    GRAPHQL_API_URL:
      process.env.NEXT_PUBLIC_GRAPHQL_API_URL ||
      'https://api.dev.tenstorrent.com',
    ADMIN_URL:
      process.env.NEXT_PUBLIC_ADMIN_URL ||
      'https://api.dev.tenstorrent.com/admin/',
    WEB_URL: process.env.NEXT_PUBLIC_WEB_URL || 'https://tenstorrent.com',
  })

  const windowDefined = typeof window !== 'undefined'

  useEffect(() => {
    if (windowDefined && process.env.NODE_ENV === 'production') {
      if (window.location.href.includes('.dev.')) {
        setUrls({
          AUTH_URL: 'https://auth.dev.tenstorrent.com',
          CLOUD_URL: 'https://cloud.dev.tenstorrent.com',
          GRAPHQL_API_URL: 'https://api.dev.tenstorrent.com',
          ADMIN_URL: 'https://api.dev.tenstorrent.com/admin/',
          WEB_URL: 'https://tenstorrent.com',
        })
      } else if (window.location.href.includes('localhost:')) {
        setUrls({
          AUTH_URL: 'http://localhost:3001',
          CLOUD_URL: 'http://localhost:3000',
          GRAPHQL_API_URL: 'http://localhost:8000',
          ADMIN_URL: 'http://localhost:8000/admin/',
          WEB_URL: 'https://tenstorrent.com',
        })
      } else {
        setUrls({
          AUTH_URL: 'https://auth.tenstorrent.com',
          CLOUD_URL: 'https://cloud.tenstorrent.com',
          GRAPHQL_API_URL: 'https://api.tenstorrent.com',
          ADMIN_URL: 'https://api.tenstorrent.com/admin/',
          WEB_URL: 'https://tenstorrent.com',
        })
      }
    }
  }, [windowDefined])

  return urls
}
