import {
  CheckIcon,
  PlusCircleIcon,
  BiggerSelectorIcon as SelectorIcon,
} from './Icons'
import { useRouter } from 'next/router'
import { useState } from 'react'
import styled from 'styled-components'
import { useOuterClick } from 'utils'
import {
  Divider,
  Dropdown,
  DropdownButton,
  Menu,
  MenuButton,
  MenuText,
  UserContext,
} from '.'

import { useContext } from 'react'

const ActiveAndSwitcher = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
`
const ActiveText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  wrap-text: nowrap;
  text-overflow: ellipsis;
`

export function TeamSwitcher(): JSX.Element | null {
  const router = useRouter()
  const { activeTeam, allUserTeams, currentUser } = useContext(UserContext)

  const [open, setOpen] = useState(false)
  const innerRef = useOuterClick(() => {
    setOpen(false)
  })

  return (
    <div ref={innerRef}>
      <ActiveAndSwitcher>
        <ActiveText>
          {activeTeam
            ? activeTeam.name
            : `${currentUser.firstName} ${currentUser.lastName}`}
        </ActiveText>
        <DropdownButton onClick={() => setOpen(!open)}>
          <SelectorIcon />
        </DropdownButton>
      </ActiveAndSwitcher>

      {open && (
        <Dropdown direction={'right'} zIndex={'10'}>
          <Menu>
            <MenuText>Personal Account</MenuText>
            <MenuButton
              $active={!activeTeam}
              onClick={() => {
                setOpen(false)
                router.push('/')
              }}
            >
              {currentUser.firstName} {currentUser.lastName}
              {!activeTeam && <CheckIcon />}
            </MenuButton>
            <MenuText>Teams</MenuText>
            {allUserTeams.length > 0 && (
              <>
                {allUserTeams.map(
                  (team) =>
                    team && (
                      <MenuButton
                        $active={activeTeam?.id === team.id}
                        key={team.id}
                        onClick={() => {
                          setOpen(false)
                          router.push(`/${team.slug}`)
                        }}
                      >
                        {team.name}
                        {activeTeam?.id === team.id && <CheckIcon />}
                      </MenuButton>
                    )
                )}
                <Divider />
              </>
            )}
            <MenuButton
              onClick={() => {
                setOpen(false)
                router.push('/create-team/')
              }}
            >
              <PlusCircleIcon />
              Create New Team
            </MenuButton>
          </Menu>
        </Dropdown>
      )}
    </div>
  )
}
