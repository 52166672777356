import { UserCircleIcon } from './Icons'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useLogoutMutation } from 'sdk'
import styled from 'styled-components'
import { useOuterClick, useUrls } from 'utils'
import {
  Divider,
  Dropdown,
  DropdownButton,
  Menu,
  MenuButton,
  MenuText,
  UserContext,
} from '.'
import { useContext } from 'react'

const TopNavDropdownContainer = styled.div`
  z-index: 3;
`

export function TopNavDropdown(): JSX.Element {
  const [open, setOpen] = useState(false)
  const innerRef = useOuterClick(() => {
    setOpen(false)
  })

  const { currentUser } = useContext(UserContext)

  const queryClient = useQueryClient()
  const router = useRouter()
  const { AUTH_URL, CLOUD_URL, ADMIN_URL } = useUrls()

  const { mutate } = useLogoutMutation({
    onSuccess: () => {
      queryClient.setQueriesData(['whoami'], { data: {} })
      queryClient.invalidateQueries(['whoami'])
      router.push(`${AUTH_URL}/?redirect=${CLOUD_URL}`)
    },
  })

  return (
    <TopNavDropdownContainer ref={innerRef}>
      {currentUser.pk && (
        <DropdownButton onClick={() => setOpen(!open)}>
          <UserCircleIcon />
        </DropdownButton>
      )}
      {open && (
        <Dropdown direction={'left'}>
          <Menu>
            {currentUser.isSuperuser && (
              <>
                <MenuText>Superuser</MenuText>
                <MenuButton
                  onClick={() => {
                    window.open(ADMIN_URL)
                  }}
                >
                  API Admin Panel
                </MenuButton>
                <Divider />
              </>
            )}
            <MenuButton
              onClick={() => {
                setOpen(false)
                router.push('/settings/profile')
              }}
            >
              User Settings
            </MenuButton>
            <Divider />
            <MenuButton
              onClick={() => {
                setOpen(false)
                mutate({ input: { confirm: true } })
              }}
            >
              Log out
            </MenuButton>
          </Menu>
        </Dropdown>
      )}
    </TopNavDropdownContainer>
  )
}

// ☰
