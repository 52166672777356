import { InputHTMLAttributes, RefAttributes } from 'react'
import styled from 'styled-components'

export const InputContainer = styled.div`
  align-items: center;
  cursor: default;
  display: flex;
  background: ${({ theme }) => theme.colors.input.background};
  color: ${({ theme }) => theme.colors.input.color};
  border-color: ${({ theme }) => theme.colors.border};
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 1px;
  transition: border-color 0.15s ease;
  overflow: hidden;

  &:focus {
    border-color: ${({ theme }) => theme.colors.foreground};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.input.disabledColor};
    background: ${({ theme }) => theme.colors.input.disabledBackground};
  }
`

export const InputHandler = styled.input`
  appearance: none;
  font: inherit;
  font-size: 1rem;
  height: 40px;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.input.background};
  color: ${({ theme }) => theme.colors.input.color};

  line-height: normal;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  min-width: 0;
  padding-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 0;
  width: 100%;

  &:disabled {
    color: ${({ theme }) => theme.colors.input.disabledColor};
    background: ${({ theme }) => theme.colors.input.disabledBackground};
  }
`

export const Input = (
  props: RefAttributes<HTMLInputElement> &
    InputHTMLAttributes<HTMLInputElement> & { After?: JSX.Element }
) => {
  const { After, ...rest } = props
  return (
    <InputContainer>
      <InputHandler {...rest} />
      {After && After}
    </InputContainer>
  )
}
