import styled from 'styled-components'

interface DividerProps {
  marginY?: string
}

export const Divider = styled.hr<DividerProps>`
  border: none;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border};
  margin-top: ${({ marginY }) => marginY || 'initial'};
  margin-bottom: ${({ marginY }) => marginY || 'initial'};};
`
