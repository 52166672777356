import styled from 'styled-components'

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding: 8px;

  :children {
    margin-bottom: 8px;
  }
`

export const MenuText = styled.p`
  padding-left: 8px;
  padding-top: 4px;
  color: ${({ theme }) => theme.colors.font.primary};
  font-size: 0.75rem;
`

interface MenuButtonProps {
  readonly $active?: boolean
}

export const MenuButton = styled.button<MenuButtonProps>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: ${({ $active }) => $active && 'space-between'};
  gap: 8px;
  border: none;
  border-radius: var(--border-radius);

  color: ${({ theme, $active }) =>
    $active ? theme.colors.foreground : theme.colors.font.primary};
  background: ${({ theme, $active }) =>
    $active ? theme.colors.menu.hoverBackground : theme.colors.background};
  transition:
    background-color 0.2s ease,
    color 0.2s ease;
  padding: 8px;

  &:hover {
    color: ${({ theme }) => theme.colors.foreground};
    background-color: ${({ theme }) => theme.colors.menu.hoverBackground};
  }
`
