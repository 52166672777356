import { useRouter } from 'next/router'
import { useContext } from 'react'
import styled from 'styled-components'
import { Menu, UserContext } from '.'
const TabNavStyles = styled(Menu)`
  grid-area: tabnav;
  overflow-x: auto;
  overflow-y: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  gap: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4px;
  padding-top: 0.5rem;

  background-color: ${({ theme }) => theme.colors.navbarBackground};

  border-bottom-color: ${({ theme }) => theme.colors.border};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`

interface TabProps {
  readonly $active?: boolean
  readonly fullWidth?: boolean
}

const Tab = styled.button<TabProps>`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;

  color: ${({ theme, $active }) =>
    $active ? theme.colors.foreground : theme.colors.font.primary};
  background: ${({ theme }) => theme.colors.background};
  transition:
    background-color 0.2s ease,
    color 0.2s ease;
  padding: 8px;
  margin-bottom: -5px;
  white-space: nowrap;

  color: ${({ theme, $active }) =>
    $active ? theme.colors.foreground : theme.colors.font.primary};

  border: none;
  border-bottom-radius: 0;
  border-bottom-color: ${({ $active, theme }) =>
    $active ? theme.colors.foreground : 'transparent'};
  border-bottom-width: 4px;
  border-bottom-style: solid;

  &:hover {
    border-radius: var(--border-radius);
    border-bottom-left-radius: ${({ $active }) =>
      $active ? '0' : 'var(--border-radius)'};
    border-bottom-right-radius: ${({ $active }) =>
      $active ? '0' : 'var(--border-radius)'};
    color: ${({ theme }) => theme.colors.foreground};
    background-color: ${({ theme }) => theme.colors.menu.hoverBackground};
  }
`

export function TabNav(): JSX.Element {
  const router = useRouter()
  const { teamSlug } = router.query
  const { activeTeam, currentUser } = useContext(UserContext)

  const baseRoute = teamSlug ? `/${teamSlug}` : ''

  const isActiveTeamPath = router.pathname.includes('/[teamSlug]') && activeTeam

  return (
    <TabNavStyles>
      {isActiveTeamPath && (
        <>
          {/* Workloads */}
          {activeTeam.isKubernetesEnabled && (
            <Tab
              onClick={() => {
                // User root needs explicit `/` to otherwise avoid double backslash
                router.push(baseRoute + '/workloads')
              }}
              $active={
                router.pathname === '/[teamSlug]/workloads' ||
                router.pathname.includes('workloads')
              }
            >
              Workloads
            </Tab>
          )}
          {/* Hardware */}
          {activeTeam.isHardwareEnabled && currentUser.isSuperuser && (
            <>
              {/* Reservations */}
              <Tab
                onClick={() => {
                  router.push(baseRoute + '/reservations?status=RESERVED')
                }}
                $active={
                  router.pathname === '/reservations' ||
                  router.pathname === '/[teamSlug]/reservations'
                }
              >
                Reservations
              </Tab>
              <Tab
                onClick={() => {
                  router.push(baseRoute + '/hardware')
                }}
                $active={router.pathname.includes('/[teamSlug]/hardware')}
              >
                Hardware
              </Tab>
              <Tab
                onClick={() => {
                  router.push(baseRoute + '/clusters')
                }}
                $active={router.pathname.includes('/[teamSlug]/clusters')}
              >
                Clusters
              </Tab>
            </>
          )}
          {/* Volumes */}
          {activeTeam.isStorageEnabled && (
            <Tab
              onClick={() => {
                router.push(baseRoute + '/storage?status=RUNNING')
              }}
              $active={router.pathname.includes('/storage')}
            >
              Storage
            </Tab>
          )}
          {/* Instances */}
          {activeTeam.isVmEnabled && (
            <Tab
              onClick={() => {
                router.push(baseRoute + '/instances?status=RUNNING')
              }}
              $active={
                router.pathname === '/instances' ||
                router.pathname === '/[teamSlug]/instances'
              }
            >
              Instances
            </Tab>
          )}
        </>
      )}
      {/* Settings */}
      {isActiveTeamPath ? (
        <Tab
          onClick={() => {
            router.push(baseRoute + '/settings')
          }}
          $active={router.pathname.includes('/[teamSlug]/settings')}
        >
          Team Settings
        </Tab>
      ) : (
        <Tab
          onClick={() => {
            router.push('/settings/profile')
          }}
          $active={router.pathname.includes('/settings/profile')}
        >
          User Settings
        </Tab>
      )}
      {/* Superuser */}
      {currentUser.isSuperuser && (
        <Tab
          onClick={() => {
            router.push(baseRoute + '/superuser')
          }}
          $active={router.pathname.includes('/superuser')}
        >
          Superuser
        </Tab>
      )}
    </TabNavStyles>
  )
}
