import { SearchIcon, XIcon } from './Icons'
import { useRouter } from 'next/router'
import { useState } from 'react'
import styled from 'styled-components'
import { useOuterInnerClick } from 'utils'
import { Code, Dropdown, Input } from '.'
// https://github.com/diegohaz/arc/issues/130 &
// https://github.com/styled-components/styled-components/issues/1449
// describe the circular dependency issue that occurred when Button was
// imported with everything else in the previous line
import { Button } from './Button'

export interface SearchFilter {
  availableFilters?: Array<string>
  defaultFilters?: Array<{
    filter: string
    value: string
  }>
}

const SearchContainer = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  position: relative;
`

const ClearButton = styled.div`
  border: none;
  background: transparent;
  display: flex;
`

const SearchInputAfter = styled.div`
  display: flex;
  align-items: center;
  align-contents: center;
  padding-right: 8px;
`

const InputWrapper = styled.div``

const PillButton = styled(Button)`
  padding: 0 10px;
  font-size: 14px;

  margin-left: 2px;
  margin-right: 2px;
  border-color: ${({ theme }) => theme.colors.button.border};
  border-radius: 50px;
  border-style: solid;
  border-width: 1px;
`

const SearchDropdown = styled(Dropdown)`
  z-index: 2;
`

const PillList = styled.div`
  display: grid;
  grid-auto-columns: auto;
  gap: 5px;
  padding: 5px;
  justify-items: start;
`

const PillBar = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
  justify-items: start;
  align-items: center;
`

const Equal = styled.span`
  &:before {
    content: '=';
  }
  width: 1em;
`

const SearchTag = ({ param, value }: { param: string; value: string }) => {
  const router = useRouter()

  const handleRemoveTag = (param: string) => {
    delete router.query[param]
    delete router.query['offset']
    router.replace({
      query: {
        ...router.query,
        offset: 0,
      },
    })
  }
  return (
    <PillButton onClick={() => handleRemoveTag(param)}>
      <Code>{param}</Code>
      <Equal />
      {value}
      <XIcon style={{ marginLeft: '5px' }} />
    </PillButton>
  )
}

function SearchTags({
  availableFilters,
}: {
  availableFilters: Array<string>
}): JSX.Element | null {
  const router = useRouter()
  const { query } = router

  if (Object.entries(query).length > 0) {
    return (
      <PillBar>
        {Object.entries(query)
          .filter(([key]) => availableFilters.includes(key))
          .map(([param, value]) => {
            const valueString = value?.toString()
            if (valueString) {
              return (
                <SearchTag
                  key={`${param}-${value}`}
                  param={param}
                  value={valueString}
                />
              )
            }
          })}
      </PillBar>
    )
  }
  return null
}

export function Search({ filters }: { filters: SearchFilter }): JSX.Element {
  const router = useRouter()
  const { query } = router
  const [search, setSearch] = useState(query.search?.toString() || '')
  const [showFilters, setShowFilters] = useState(false)

  const refForClickHandler = useOuterInnerClick(
    () => {
      setShowFilters(true)
    },
    () => {
      setShowFilters(false)
    }
  )

  return (
    <SearchContainer>
      <InputWrapper ref={refForClickHandler}>
        <Input
          value={search}
          onChange={(event) => setSearch(event.target.value.toString() || '')}
          After={
            <SearchInputAfter>
              {search.length > 0 ? (
                <ClearButton onClick={() => setSearch('')}>
                  <XIcon />
                </ClearButton>
              ) : (
                <SearchIcon />
              )}
            </SearchInputAfter>
          }
        />
      </InputWrapper>
      {showFilters && (
        <SearchDropdown>
          <PillList>
            {showFilters && filters && filters.availableFilters && (
              <>
                {filters.availableFilters.map((availableFilter) => (
                  <PillButton
                    onClick={() => {
                      router.replace({
                        query: {
                          ...router.query,
                          offset: 0,
                          [availableFilter]: search,
                        },
                      })
                    }}
                    key={availableFilter}
                  >
                    <Code>{availableFilter}</Code>
                    <Equal />
                    {search}
                  </PillButton>
                ))}
              </>
            )}
            {showFilters && filters && filters.defaultFilters && (
              <>
                {filters.defaultFilters.map(({ filter, value }) => (
                  <PillButton
                    onClick={() => {
                      router.replace({
                        query: {
                          ...router.query,
                          offset: 0,
                          [filter]: value,
                        },
                      })
                    }}
                    key={`${filter}=${value}`}
                  >
                    <Code>{filter}</Code>
                    <Equal />
                    {value}
                  </PillButton>
                ))}
              </>
            )}
          </PillList>
        </SearchDropdown>
      )}
      <SearchTags availableFilters={filters.availableFilters || []} />
    </SearchContainer>
  )
}
