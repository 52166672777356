import styled from 'styled-components'
import { Link } from './Link'
import { Logo } from './Logo'

const CloudFooterStyles = styled.footer`
  border-top-color: ${({ theme }) => theme.colors.border};
  border-top-style: solid;
  border-top-width: 1px;
  display: grid;
  grid-area: footer;
  max-width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 16px;
  align-items: center;
  grid-auto-flow: column;
  justify-content: space-between;
`

const LinkGroup = styled.div`
  gap: 8px;
`
export const CloudFooter = (): JSX.Element => {
  return (
    <CloudFooterStyles>
      <Logo />
      <LinkGroup>
        <Link href="https://tenstorrent.com/privacy-terms/">
          {'Privacy & Terms'}
        </Link>
      </LinkGroup>
    </CloudFooterStyles>
  )
}
