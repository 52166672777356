import { ButtonHTMLAttributes } from 'react'
import { Button } from './Button'
import { useModal } from './ModalContext'
import { ConfirmForm } from './ConfirmForm'

type ButtonWithConfirmProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  promptItems: string[]
  buttonLabel: string
  prompt?: string
  onSubmit: () => Promise<string | null>
  // Called after onSubmit finishes successfully and modal is closed
  onModalSuccess?: () => void
}

export function ButtonWithConfirm({
  buttonLabel,
  promptItems,
  prompt,
  onSubmit,
  onModalSuccess,
  ...props
}: ButtonWithConfirmProps): JSX.Element {
  const { openModal, closeModal } = useModal()

  const Component = (
    <ConfirmForm
      heading={buttonLabel}
      prompt={prompt}
      promptItems={promptItems}
      onSubmit={onSubmit}
      onCancel={closeModal}
      onSubmitComplete={() => {
        closeModal()
        onModalSuccess?.()
      }}
    />
  )

  return (
    <Button type={'button'} onClick={() => openModal({ Component })} {...props}>
      {buttonLabel}
    </Button>
  )
}
