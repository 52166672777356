import styled from 'styled-components'

export const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.font.secondary};
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  width: inherit;
`
