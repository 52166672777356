import styled from 'styled-components'
import { Td } from './Td'
import { PropsWithChildren } from 'react'

const Disclaimer = styled(Td)`
  padding: 30px;
  text-align: center;
`

type TbodyDisclaimerProps = PropsWithChildren<{ colSpan: number }>

export const TbodyDisclaimer = (props: TbodyDisclaimerProps): JSX.Element => {
  return (
    <tbody>
      <tr>
        <Disclaimer colSpan={props.colSpan}>{props.children}</Disclaimer>
      </tr>
    </tbody>
  )
}
