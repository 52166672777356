import { Form } from './Form'
import { SubHeading } from './Typography'
import { FormError } from './FormError'
import { ButtonRow } from './ButtonRow'
import { useState } from 'react'
import { Button, ButtonAsync } from './Button'

interface ConfirmFormProps {
  heading: string
  prompt?: string
  // Return array of errors
  onSubmit: () => Promise<string | null>
  onSubmitComplete: () => void
  onCancel: () => void
  // Rendered for context of confirmation
  promptItems: string[]
}

export function ConfirmForm({
  heading,
  prompt,
  onSubmitComplete,
  onSubmit,
  onCancel,
  promptItems,
}: ConfirmFormProps): JSX.Element {
  const [submitError, setSubmitError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Form
      onReset={onCancel}
      onSubmit={async (event) => {
        event.preventDefault()
        setIsLoading(true)
        const errorMessage = await onSubmit()
        if (!errorMessage) {
          // Close after all deletes have been received by the backend
          onSubmitComplete()
        } else {
          setSubmitError(errorMessage)
          setIsLoading(false)
        }
      }}
    >
      <SubHeading>{heading}</SubHeading>
      {prompt && <p>{prompt}</p>}
      <ul>
        {promptItems.map((resource, index) => (
          <li key={index}>{resource}</li>
        ))}
      </ul>
      {submitError != null && <FormError>{submitError}</FormError>}
      <ButtonRow>
        <Button type={'reset'}>Cancel</Button>
        <ButtonAsync type={'submit'} loading={isLoading} danger={true}>
          {submitError ? `Retry` : `Confirm`}
        </ButtonAsync>
      </ButtonRow>
    </Form>
  )
}
