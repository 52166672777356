import { useRouter } from 'next/router'
import { FormEventHandler, useEffect, useState } from 'react'
import { SignupInput, useSignupMutation } from 'sdk'
import styled from 'styled-components'
import { useUrls } from 'utils'
import { Button, Form, FormError, FormTextInput, Heading, SubHeading } from '.'

interface ERROR {
  message: string
}

const SignupFormStyles = styled(Form)`
  height: 100%;
`

export function SignupForm(): JSX.Element {
  const router = useRouter()
  const { CLOUD_URL } = useUrls()
  const { mutate, isLoading, isSuccess, isError, error } = useSignupMutation()

  const typedError = error as ERROR

  if (isSuccess) {
    const { redirect } = router.query || {}
    if (redirect) {
      router.push(redirect.toString())
    } else {
      router.push(CLOUD_URL)
    }
  }

  const [ready, setReady] = useState(false)
  useEffect(() => {
    if (router.isReady) {
      setReady(true)
    }
  }, [router.isReady])

  // Optional signup code and email provided through the URL
  const { email, signupCode } = router.query || {}

  if (!ready) {
    return <></>
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    const input: SignupInput = {
      signupCode: formData.get('signupCode')?.toString() || '',
      email: formData.get('email')?.toString() || '',
      firstName: formData.get('firstName')?.toString() || '',
      lastName: formData.get('lastName')?.toString() || '',
      username: formData.get('username')?.toString() || '',
      password: formData.get('password')?.toString() || '',
    }
    const pwConfirmation =
      formData.get('passwordConfirmation')?.toString() || ''
    const pwMessage = document.getElementById('pwMessage')
    if (pwMessage != null) {
      if (input.password != pwConfirmation) {
        pwMessage.innerHTML = 'Password does not match Password Confirmation'
      } else {
        pwMessage.innerHTML = ''
        const variables = { input }
        await mutate(variables)
      }
    }
  }

  return (
    <SignupFormStyles onSubmit={onSubmit}>
      <Heading>Sign up for Tenstorrent Cloud</Heading>
      <p>Please ask for a signup code from your Tenstorrent contact.</p>
      <FormTextInput
        label={'Signup code'}
        type="signupCode"
        id="signupCode"
        name="signupCode"
        defaultValue={signupCode}
        required
      />
      <SubHeading>Account details</SubHeading>
      <FormTextInput
        label={'Username'}
        type="username"
        id="username"
        name="username"
        required
      />
      <FormTextInput
        label={'Email'}
        type="email"
        id="email"
        name="email"
        defaultValue={email}
        required
      />
      <FormTextInput
        label={'First Name'}
        type="fname"
        id="firstName"
        name="firstName"
        required
      />
      <FormTextInput
        label={'Last Name'}
        type="lname"
        id="lastName"
        name="lastName"
        required
      />
      <FormTextInput
        label={'Password'}
        type="password"
        id="password"
        name="password"
        required
      />
      <FormTextInput
        label={'Password Confirmation'}
        type="password"
        id="passwordConfirmation"
        name="passwordConfirmation"
        required
      />
      {<FormError id="pwMessage">{}</FormError>}
      {isError && <FormError>{typedError.message.toString()}</FormError>}
      <Button disabled={isLoading} type="submit">
        Sign Up
      </Button>
    </SignupFormStyles>
  )
}
