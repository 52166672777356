import styled from 'styled-components'

interface VerticalDividerProps {
  readonly color?: string
}

export const VerticalDivider = styled.div<VerticalDividerProps>`
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: ${({ color, theme }) => color || theme.colors.border};
  height: 100%;
`
