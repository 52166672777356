import { default as NextLink, LinkProps } from 'next/link'
import styled from 'styled-components'
import { ExternalLinkIcon } from './Icons'

const StyledLink = styled.span`
  font: inherit;
  font-size: inherit;
  font-weight: semi-bold;
  line-height: 1.5rem;
  color: var(--tt-link);
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  min-width: 0;
  text-decoration-line: underline;
  text-underline-offset: 4px;
`

export const Link = (
  props: Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
    LinkProps & {
      children?: React.ReactNode
    } & React.RefAttributes<HTMLAnchorElement>
) => {
  const { style, ...linkProps } = props
  return (
    <NextLink {...linkProps} style={{ textDecoration: 'none' }}>
      <StyledLink style={style}>
        {props.children}
        {props.target === '_blank' && (
          <ExternalLinkIcon style={{ marginLeft: '4px' }} />
        )}
      </StyledLink>
    </NextLink>
  )
}

export function triggerLink(
  url: string,
  target: string = '_self',
  download?: string
) {
  const element = document.createElement('a')
  element.href = url
  element.target = target
  if (download) {
    element.download = download
  }

  document.body.appendChild(element)
  element.click()
}
