import { InputHTMLAttributes, useState } from 'react'
import styled from 'styled-components'
import { Input } from './Input'
import { Label } from './Label'
import { RequiredInputDecorator } from './RequiredInputDecorator'
import { Hint } from './Hint'

const LabelContainer = styled.div`
  padding-bottom: 6px;
`

type FormTextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string
  validate?: (value: string) => boolean
  validationErrorMessage?: string
  autoFocus?: boolean
  helpText?: string
  // Waiting to be pre-populated
  isFetching?: boolean
}

export const FormTextInput = (props: FormTextInputProps): JSX.Element => {
  const [isValidInput, setIsValidInput] = useState(true)
  const {
    validate,
    label,
    validationErrorMessage,
    helpText,
    required,
    isFetching,
    disabled,
    value,
    ...rest
  } = props

  return (
    <Label>
      <LabelContainer>
        {label}
        {helpText && (
          <>
            {' '}
            <Hint message={helpText} />
          </>
        )}
        {required && <RequiredInputDecorator />}
      </LabelContainer>

      <Input
        onBlur={(event) => {
          setIsValidInput(validate?.(event.currentTarget.value) ?? true)
        }}
        disabled={disabled || isFetching}
        value={isFetching ? '' : value}
        {...rest}
      />
      {!isValidInput && validationErrorMessage && (
        <div>{validationErrorMessage}</div>
      )}
    </Label>
  )
}
