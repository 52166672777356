type ButtonTheme = {
  color: string
  hoverColor: string
  hoverBackground: string
  border: string
  hoverBorder: string
  background: string
  activeBackground: string
}

type InputTheme = {
  color: string
  disabledColor: string
  background: string
  disabledBackground: string
}

interface ThemeInterface {
  theme: 'light' | 'dark'
  colors: {
    background: string
    foreground: string
    hoverBackground: string
    navbarBackground: string
    border: string
    font: {
      primary: string
      secondary: string
      success: string
      error: string
      warning: string
      highlight: string
    }
    button: ButtonTheme
    buttonSecondary: ButtonTheme
    buttonDisabled: ButtonTheme
    buttonSubmit: ButtonTheme
    buttonSubmitDanger: ButtonTheme
    input: InputTheme
    menu: {
      hoverBackground: string
    }
    pagination: {
      backgroundColor: string
      disabledBackgroundColor: string
      hoverBackgroundColor: string
      activeBackgroundColor: string
    }
    table: {
      thBackground: string
      loadingBackground: string
      hoverBackground: string
    }
  }
}

export const LightTheme: ThemeInterface = {
  theme: 'light',
  colors: {
    background: 'var(--background)',
    foreground: 'var(--foreground)',
    hoverBackground: 'var(--hover-background)',
    navbarBackground: 'var(--background)',
    border: '#DDDDDD',
    font: {
      primary: '#000000',
      secondary: '#666666',
      success: 'green',
      error: 'red',
      warning: 'orange',
      highlight: '#DDDDDD',
    },
    button: {
      color: '#666666',
      hoverColor: 'black',
      hoverBackground: '#EEEEEE',
      border: '#cccccc',
      hoverBorder: 'black',
      background: 'white',
      activeBackground: 'transparent',
    },
    buttonSecondary: {
      background: '#666',
      border: '#666',
      color: 'white',
      hoverBackground: 'transparent',
      hoverBorder: '#000',
      hoverColor: '#000',
      activeBackground: 'transparent',
    },
    buttonDisabled: {
      color: '#ccc',
      hoverColor: '#ccc',
      border: '#999',
      hoverBorder: '#999',
      background: '#999',
      hoverBackground: '#999',
      activeBackground: '#999',
    },
    buttonSubmit: {
      color: '#eee',
      hoverColor: 'white',
      border: '#393',
      background: '#393',
      hoverBorder: '#44cb44',
      hoverBackground: '#308d30',
      activeBackground: '#247024',
    },
    buttonSubmitDanger: {
      color: '#eee',
      hoverColor: 'white',
      border: '#C33',
      background: '#C33',
      hoverBorder: '#F66',
      hoverBackground: '#c04040',
      activeBackground: '#702424',
    },
    input: {
      color: 'black',
      background: 'transparent',
      disabledBackground: '#ddd',
      disabledColor: 'black',
    },
    menu: {
      hoverBackground: '#EEEEEE',
    },
    pagination: {
      backgroundColor: '#FFFFFF',
      disabledBackgroundColor: '#EEEEEE',
      hoverBackgroundColor: '#CCCCCC',
      activeBackgroundColor: '#DDDDDD',
    },
    table: {
      thBackground: '#EEEEEE',
      loadingBackground: '#EEEEEE',
      hoverBackground: '#D7E1ED',
    },
  },
}

export const DarkTheme: ThemeInterface = {
  theme: 'dark',
  colors: {
    background: 'var(--background)',
    foreground: 'var(--foreground)',
    hoverBackground: 'var(--hover-background)',
    navbarBackground: 'var(--background)',
    border: '#333333',
    font: {
      primary: '#eeeeee',
      secondary: '#999999',
      success: 'green',
      error: 'red',
      warning: 'orange',
      highlight: '#666666',
    },
    button: {
      color: '#cccccc',
      hoverColor: 'white',
      border: '#666666',
      hoverBorder: 'white',
      hoverBackground: '#222222',
      background: 'black',
      activeBackground: 'transparent',
    },
    buttonSecondary: {
      background: '#CCCCCC',
      border: '#CCCCCC',
      color: 'black',
      hoverBackground: 'transparent',
      hoverBorder: 'white',
      hoverColor: 'white',
      activeBackground: 'transparent',
    },
    buttonDisabled: {
      color: '#ccc',
      hoverColor: '#ccc',
      border: '#999',
      hoverBorder: '#999',
      background: '#999',
      hoverBackground: '#999',
      activeBackground: '#999',
    },
    buttonSubmit: {
      color: '#ccc',
      hoverColor: '#eee',
      border: '#63c',
      background: '#63c',
      hoverBorder: '#743ae7',
      hoverBackground: '#572bab',
      activeBackground: '#316',
    },
    buttonSubmitDanger: {
      color: '#eee',
      hoverColor: 'white',
      border: '#933',
      background: '#933',
      hoverBorder: '#cb4444',
      hoverBackground: '#8d3030',
      activeBackground: '#702424',
    },
    input: {
      color: '#eee',
      background: 'transparent',
      disabledBackground: '#666',
      disabledColor: '#eee',
    },
    menu: {
      hoverBackground: '#333333',
    },
    pagination: {
      backgroundColor: '#000000',
      disabledBackgroundColor: '#111111',
      hoverBackgroundColor: '#222222',
      activeBackgroundColor: '#333333',
    },
    table: {
      thBackground: '#222222',
      loadingBackground: '#444444',
      hoverBackground: '#3567A3',
    },
  },
}
