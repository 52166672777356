import { useState } from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import { FormTextInput } from './FormTextInput'

const KeyValueContainer = styled.div``
const KeyValueRowStyles = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`

const AddKeyValuePairButton = styled(Button)`
  height: 42px;
  align-self: flex-end;
`

const RemoveKeyPairButton = styled(Button)`
  height: 42px;
  align-self: flex-end;
`

const KeyValueRow = ({
  record,
  onChange,
  keyName,
  value,
  existing,
}: {
  record: Record<string, string>
  onChange: (record: Record<string, string>) => void
  keyName?: string
  value?: string
  existing?: boolean
}): JSX.Element => {
  const [workingKey, setWorkingKey] = useState(keyName || '')
  const [workingValue, setWorkingValue] = useState(value || '')

  const updatedRecord = (key: string, value: string) => {
    onChange({ ...record, [key]: value })
    if (existing !== false) {
      setWorkingKey('')
      setWorkingValue('')
    }
  }

  const removePairFromRecord = (key: string) => {
    const placeholderRecord = { ...record }
    delete placeholderRecord[key]
    onChange(placeholderRecord)
  }

  return (
    <KeyValueRowStyles>
      <FormTextInput
        label={'Key'}
        type="text"
        id="key"
        name="key"
        value={workingKey}
        onChange={(event) => setWorkingKey(event.currentTarget.value)}
        required
      />
      <FormTextInput
        label={'Value'}
        type="text"
        id="value"
        name="value"
        value={workingValue}
        onChange={(event) => setWorkingValue(event.currentTarget.value)}
        required
      />
      <AddKeyValuePairButton
        onClick={(event) => {
          event.preventDefault()
          updatedRecord(workingKey, workingValue)
        }}
      >
        {record?.[workingKey] ? 'Edit ' : 'Add'}
      </AddKeyValuePairButton>
      <RemoveKeyPairButton
        onClick={(event) => {
          event.preventDefault()
          removePairFromRecord(workingKey)
        }}
      >
        Remove
      </RemoveKeyPairButton>
    </KeyValueRowStyles>
  )
}

export function KeyValueInput({
  record,
  onChange,
}: {
  record: Record<string, string>
  onChange: (record: Record<string, string>) => void
}): JSX.Element {
  return (
    <KeyValueContainer>
      {Object.entries(record).length > 0 &&
        Object.entries(record).map(([keyName, value]) => {
          return (
            <KeyValueRow
              key={keyName}
              record={record}
              onChange={onChange}
              keyName={keyName}
              value={value}
              existing
            />
          )
        })}
      <KeyValueRow record={record} onChange={onChange} />
    </KeyValueContainer>
  )
}
