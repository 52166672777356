import { useTheme } from 'next-themes'
import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'
import { useUrls } from 'utils'
// TODO: fix this later with babel plugin
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logoWhiteText from './public/logo_with_white_text.svg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logoBlackText from './public/logo_with_black_text.svg'

const LogoStyles = styled.div`
  display: flex;
  align-items: center;
`
const ImageStyles = styled(Image)`
  /* Size is a bounding-box, logo will scale centered within it */
  height: 46px;
  width: 150px;
`

export function Logo({ href }: { href?: string }): JSX.Element {
  const { WEB_URL } = useUrls()
  const { resolvedTheme } = useTheme()

  return (
    <Link href={href ? href : WEB_URL}>
      <LogoStyles>
        <ImageStyles
          alt="Tenstorrent logo"
          src={resolvedTheme === 'dark' ? logoWhiteText : logoBlackText}
          layout="fixed"
        />
      </LogoStyles>
    </Link>
  )
}
