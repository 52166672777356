import { useEffect, useRef } from 'react'

export function useOuterClick(callback: () => void) {
  const callbackRef = useRef<() => void>() // initialize mutable ref, which stores callback
  const innerRef = useRef<HTMLDivElement>(null) // returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback
  })

  useEffect(() => {
    function handleClick(this: Document, event: MouseEvent) {
      const target = event.target as HTMLElement
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(target)
      )
        callbackRef.current()
    }
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, []) // no dependencies -> stable click listener

  return innerRef // convenience for client (doesn't need to init ref himself)
}
