import { useEffect, useRef } from 'react'

export function useOuterInnerClick(
  innerCallback: () => void,
  outerCallback: () => void
) {
  const innerCallbackRef = useRef<() => void>() // initialize mutable ref, which stores callback
  const outerCallbackRef = useRef<() => void>() // initialize mutable ref, which stores callback
  const innerRef = useRef<HTMLDivElement>(null) // returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    innerCallbackRef.current = innerCallback
    outerCallbackRef.current = outerCallback
  })

  useEffect(() => {
    function handleClick(this: Document, event: MouseEvent) {
      const target = event.target as HTMLElement
      if (
        innerRef.current &&
        innerCallbackRef.current &&
        innerRef.current.contains(target)
      )
        innerCallbackRef.current()
      if (
        innerRef.current &&
        outerCallbackRef.current &&
        !innerRef.current.contains(target)
      )
        outerCallbackRef.current()
    }
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, []) // no dependencies -> stable click listener

  return innerRef // convenience for client (doesn't need to init ref himself)
}
