import styled from 'styled-components'

export const AccordionTitle = styled.div`
  font-weight: bold;
  font-family: Akkurat-Mono;
  cursor: pointer;
  display: inline-block;
  margin: 5px 0;
  border-bottom: 1px dotted transparent;
  &:hover {
    border-bottom: 1px dotted black;
  }
`
