import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
:root {
  --tt-black: #231F20;
  --tt-new-gray: #454455;
  --tt-dark-blue: #33333D;
  --tt-dark-green: #3B7774;
  --tt-purple: #533B60;
  --tt-green: #20B57E;
  --tt-link: black;
  --background: white;
  --foreground: black;
  --hover-background: #EEEEEE;
  --border-radius: 5px;
}

[data-theme='dark'] {
  --background: black;
  --foreground: white;
  --hover-background: #333333;
  --tt-link: white;
}

  /*
  Initial set of rules can be found here:
  https://www.joshwcomeau.com/css/custom-css-reset/
  */

  *, *::before, *::after {
    box-sizing: border-box;
  }
  * {
    // font-family: "Roboto", "Lucida Grande", "DejaVu Sans", "Bitstream Vera Sans", Verdana, Arial, sans-serif;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
  }
  html, body {
    height: 100%;
    background-color: var(--background);
  }
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  img, picture, video, canvas, svg {
    display: inline-block;
  }
  input, button, textarea, select {
    font: inherit;
  }
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }
  #root, #__next {
    isolation: isolate;
  }
  #__next {
    height: 100%;
  }
`
