import { ThemeProvider as NextThemeProvider, useTheme } from 'next-themes'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { FetchParamsProvider, useWhoamiQuery } from 'sdk'
import { ThemeProvider } from 'styled-components'
import {
  CloudFooter,
  DarkTheme,
  GlobalStyle,
  LightTheme,
  ModalProvider,
  TabNav,
  TeamProvider,
  TopLevelGrid,
  TopNav,
} from 'ui'
import { useUrls } from 'utils'

function AppWithContext({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const { AUTH_URL, CLOUD_URL } = useUrls()
  const { data, isLoading, isError, isSuccess, isFetched } = useWhoamiQuery(
    undefined,
    {
      enabled: typeof window !== 'undefined',
      retry: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )
  const user = data?.whoami

  if (isFetched && !isLoading && user?.id === undefined) {
    router.push(`${AUTH_URL}/?redirect=${CLOUD_URL}`)
  }
  if (isFetched && isError) {
    router.push(`${AUTH_URL}/?redirect=${CLOUD_URL}`)
  }

  if (user && isSuccess) {
    return (
      <TeamProvider currentUser={user}>
        <ModalProvider>
          <TopLevelGrid>
            <TopNav />
            <TabNav />
            <Component {...pageProps} />
            <CloudFooter />
            {user.isSuperuser && <ReactQueryDevtools />}
          </TopLevelGrid>
        </ModalProvider>
      </TeamProvider>
    )
  } else {
    return null
  }
}

function AppWithStyles(props: AppProps) {
  const { resolvedTheme } = useTheme()
  const queryClient = new QueryClient()

  return (
    <ThemeProvider theme={resolvedTheme === 'light' ? LightTheme : DarkTheme}>
      <GlobalStyle />
      <FetchParamsProvider>
        <QueryClientProvider client={queryClient}>
          <AppWithContext {...props} />
        </QueryClientProvider>
      </FetchParamsProvider>
    </ThemeProvider>
  )
}

export default function App(props: AppProps): JSX.Element {
  return (
    <>
      <NextThemeProvider>
        <AppWithStyles {...props} />
      </NextThemeProvider>
    </>
  )
}
