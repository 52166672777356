import styled from 'styled-components'
const RequiredInputDecoratorStyles = styled.abbr`
  margin-left: 2px;
`

export function RequiredInputDecorator(): JSX.Element {
  return (
    <RequiredInputDecoratorStyles
      title="This field is mandatory"
      aria-label="required"
    >
      *
    </RequiredInputDecoratorStyles>
  )
}
