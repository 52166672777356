import { createContext, Dispatch, useContext, useReducer } from 'react'
import styled from 'styled-components'

const ModalContainer = styled.div`
  z-index: 4;
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`

const Modal = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  margin: auto;

  border-radius: var(--border-radius);
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};

  min-width: 225px;
  padding: 10px;
`

interface ModalReducerState {
  isOpen: boolean
  Component: JSX.Element | null | undefined
}

const initialModalState: ModalReducerState = {
  isOpen: false,
  Component: null,
}

interface ModalContextInterface {
  state: ModalReducerState
  dispatch: Dispatch<{ type: string; Component?: JSX.Element }>
}

export const ModalContext = createContext<ModalContextInterface>({
  state: initialModalState,
  dispatch: () => undefined,
})

function modalReducer(
  _state: ModalReducerState,
  action: { type: string; Component?: JSX.Element | null }
): ModalReducerState {
  switch (action.type) {
    case 'OPEN':
      return { isOpen: true, Component: action.Component }
    case 'CLOSE':
      return { isOpen: false, Component: null }
    default:
      throw new Error()
  }
}

const ModalHandler = (): JSX.Element => {
  const { state, dispatch } = useContext(ModalContext)
  return (
    <ModalContainer
      onClick={() => {
        dispatch({ type: 'CLOSE' })
      }}
    >
      <Modal
        onClick={(event) => {
          // Prevent event from leaking to the overlay that closes the modal
          event.stopPropagation()
        }}
      >
        {state.Component}
      </Modal>
    </ModalContainer>
  )
}

export const ModalProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [state, dispatch] = useReducer(modalReducer, initialModalState)

  return (
    <ModalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {state.isOpen && <ModalHandler />}
      {children}
    </ModalContext.Provider>
  )
}

export function useModal() {
  const { dispatch } = useContext(ModalContext)

  const openModal = ({ Component }: { Component: JSX.Element }) => {
    dispatch({
      type: 'OPEN',
      Component: Component,
    })
  }

  const closeModal = () => {
    dispatch({
      type: 'CLOSE',
    })
  }

  return { openModal, closeModal }
}
