import styled from 'styled-components'
import { FormTextInput } from './FormTextInput'

const ValueContainer = styled.div``
const ValueRowStyles = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`

function ValueRow<K extends Record<string, string>>({
  record,
  keyLabels,
  onChange,
  keyName,
}: {
  keyLabels: Record<keyof K, string>
  record: K
  onChange: (record: Record<string, string>) => void
  keyName: keyof K
}): JSX.Element {
  return (
    <ValueRowStyles>
      <FormTextInput
        label={keyLabels[keyName]}
        type="text"
        id="value"
        name="value"
        value={record[keyName]}
        onChange={(event) =>
          onChange({ ...record, [keyName]: event.currentTarget.value })
        }
        required
      />
    </ValueRowStyles>
  )
}

export function ValueInput<K extends Record<string, string>>({
  record,
  keyLabels,
  onChange,
}: {
  keyLabels: Record<keyof K, string>
  record: K
  onChange: (record: Record<string, string>) => void
}): JSX.Element {
  return (
    <ValueContainer>
      {Object.entries(record).length > 0 &&
        Object.entries(record).map(([keyName]) => {
          return (
            <ValueRow
              key={keyName}
              keyLabels={keyLabels}
              record={record}
              onChange={onChange}
              keyName={keyName}
            />
          )
        })}
    </ValueContainer>
  )
}
