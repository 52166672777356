import { useContext } from 'react'
import { FetchParamsContext } from 'sdk'
import styled from 'styled-components'

const ConnectionErrorStyles = styled.div`
  grid-area: footer;
  color: ${(props) => props.theme.colors.font.error};
  margin: auto;
  margin-top: 36px;
`

export function ConnectionError() {
  const { connectedSuccessfully } = useContext(FetchParamsContext)
  if (connectedSuccessfully === false) {
    return (
      <ConnectionErrorStyles>Could not connect to API</ConnectionErrorStyles>
    )
  }
  return null
}
