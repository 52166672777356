import styled from 'styled-components'

export const List = styled.ul`
  list-style: none;
  border-color: ${({ theme }) => theme.colors.border};
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  li:not(:last-child) {
    border-bottom-color: ${({ theme }) => theme.colors.border};
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
`

interface ListItemProps {
  readonly $active?: boolean
  readonly $disabled?: boolean
}

export const ListItem = styled.li<ListItemProps>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;

  border: none;
  border-left-radius: 0;
  border-left-color: ${({ $active, theme }) =>
    $active ? theme.colors.foreground : 'transparent'};
  border-left-width: 4px;
  border-left-style: solid;

  color: ${({ $disabled, theme }) =>
    $disabled ? theme.colors.input.disabledColor : theme.colors.input.color};
  background-color: ${({ $disabled, theme }) =>
    $disabled
      ? theme.colors.input.disabledBackground
      : theme.colors.input.background};

  &:hover {
    border-left-left-radius: ${({ $active }) =>
      $active ? '0' : 'var(--border-radius)'};
    border-left-right-radius: ${({ $active }) =>
      $active ? '0' : 'var(--border-radius)'};
    color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.input.disabledColor : theme.colors.foreground};
    background-color: ${({ theme, $disabled }) =>
      $disabled
        ? theme.colors.input.disabledBackground
        : theme.colors.menu.hoverBackground};
  }
`
