import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { Label, Select } from '.'

const Text = styled.div`
  padding-bottom: 5px;
`

type FormSelectProps = Omit<
  InputHTMLAttributes<HTMLSelectElement>,
  'onChange'
> & {
  label: string
  placeholder: string
  currentValue: string | null
  options: Array<{ value: string; label: string }>
  onChange: (value: string) => void
}

export const FormSelect = (props: FormSelectProps): JSX.Element => {
  const {
    value,
    label,
    options,
    onChange,
    currentValue,
    placeholder,
    ...attributes
  } = props

  return (
    <Label>
      <Text>{label}</Text>
      <Select
        onChange={(event) => {
          const key = event.currentTarget.value
          onChange(key)
        }}
        value={value ?? undefined}
        {...attributes}
      >
        <option disabled selected={!currentValue}>
          {placeholder}
        </option>
        {options.length === 0 ? (
          <option disabled>No options available</option>
        ) : (
          options.map(({ value, label }) => (
            <option key={value} value={value} selected={value === currentValue}>
              {label}
            </option>
          ))
        )}
      </Select>
    </Label>
  )
}
