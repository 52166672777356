import { Select } from './Select'
import { List, ListItem } from './List'
import { SelectHTMLAttributes } from 'react'
import styled from 'styled-components'
import { XIcon } from './Icons'

type SelectMultipleProps = SelectHTMLAttributes<HTMLSelectElement> & {
  title: string // Default <select> string visible to the user
  options: Record<string, string>
  selectedIds: string[]
  onSelectedIdsChange: (selectedIds: string[]) => void
}

const RemoveButtonStyles = styled.button`
  background: none;
  padding: none;
  margin: none;
  border: none;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.input.disabledColor : theme.colors.input.color};
`

export function SelectMultiple({
  title,
  options,
  selectedIds,
  onSelectedIdsChange,
  ...props
}: SelectMultipleProps) {
  const entries = Object.entries(options)

  return (
    <>
      <Select
        {...props}
        defaultValue={''}
        onChange={(event) => {
          const id = event.target.value
          onSelectedIdsChange([...selectedIds, id])
          // reset the select dropdown
          event.target.value = ''
        }}
      >
        <option disabled value={''}>
          {title}
          {selectedIds.length > 0 &&
            ` (${selectedIds.length}/${entries.length} selected)`}
        </option>
        {entries
          .filter(([id]) => !selectedIds.includes(id))
          .map(([id, label]) => {
            return (
              <option key={id} value={id}>
                {label}
              </option>
            )
          })}
      </Select>
      {selectedIds.length > 0 && (
        <List>
          {entries
            .filter(([id]) => selectedIds.includes(id))
            .map(([id, label]) => {
              return (
                <ListItem key={id} $disabled={props.disabled}>
                  <span>{label}</span>
                  <RemoveButtonStyles
                    disabled={props.disabled}
                    onClick={(event) => {
                      event.preventDefault()
                      onSelectedIdsChange(
                        selectedIds.filter((selectedId) => selectedId !== id)
                      )
                    }}
                  >
                    <XIcon />
                  </RemoveButtonStyles>
                </ListItem>
              )
            })}
        </List>
      )}
    </>
  )
}
