import { HintIcon } from './Icons'
import styled from 'styled-components'
import { PropsWithChildren } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'

const HintStyles = styled.span`
  flex-wrap: nowrap;
  font-size: 1rem;
  cursor: help;
  border-bottom-color: ${({ theme }) => theme.colors.font.secondary};
  border-bottom-style: dotted;
  border-bottom-width: 1px;
`

const Tooltip = styled(ReactTooltip)`
  text-transform: none;
  max-width: 30em;
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

type HintProps = PropsWithChildren<{ message: string }>

export const Hint = ({ children, message }: HintProps): JSX.Element => {
  const id = 'hintid-' + Math.random().toString(16).slice(2)
  return (
    <>
      <HintStyles
        data-tooltip-id={id}
        onClick={() => navigator.clipboard.writeText(message)}
      >
        {children && <>{children}&nbsp;</>}
        <HintIcon />
      </HintStyles>
      <Tooltip id={id}>(click to copy) {message}</Tooltip>
    </>
  )
}
