import { ChevronDownIcon } from './Icons'
import { SelectHTMLAttributes } from 'react'
import styled from 'styled-components'

export const SelectContainer = styled.div<{ disabled?: boolean }>`
  align-items: center;
  cursor: default;
  display: flex;
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.input.disabledBackground : theme.colors.background};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.input.disabledColor : theme.colors.input.color};
  border-color: ${({ theme }) => theme.colors.border};
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 1px;
  transition: border-color 0.15s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.foreground};
  }
`

export const SelectHandler = styled.select`
  appearance: none;
  font: inherit;
  font-size: 1rem;
  height: 40px;
  border: none;
  outline: none;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.input.disabledColor : theme.colors.input.color};
  background: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.input.disabledBackground
      : theme.colors.input.background};
  width: 100%;
  line-height: normal;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  min-width: 0;
  padding-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 0;
`

export const Select = (props: SelectHTMLAttributes<HTMLSelectElement>) => {
  return (
    <SelectContainer disabled={props.disabled}>
      <SelectHandler {...props} />
      <ChevronDownIcon />
    </SelectContainer>
  )
}
