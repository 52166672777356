import { InputHTMLAttributes, useState } from 'react'
import { Label } from './Label'
import styled from 'styled-components'
import { RequiredInputDecorator } from './RequiredInputDecorator'

const Text = styled.div`
  padding-bottom: 5px;
`

const Textarea = styled.textarea`
  width: 100%;
`

type FormTextareaProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  label: string
  validate?: (value: string) => boolean
  validationErrorMessage?: string
}

export const FormTextarea = (props: FormTextareaProps): JSX.Element => {
  const [isValidInput, setIsValidInput] = useState(true)
  const {
    validate,
    label,
    validationErrorMessage,
    required,
    value,
    ...attributes
  } = props

  return (
    <Label>
      <Text>
        {label}
        {required && <RequiredInputDecorator />}
      </Text>
      <Textarea
        placeholder={label}
        onBlur={(event) => {
          setIsValidInput(validate?.(event.currentTarget.value) ?? true)
        }}
        {...attributes}
      >
        {value}
      </Textarea>
      {!isValidInput && validationErrorMessage && (
        <div>{validationErrorMessage}</div>
      )}
    </Label>
  )
}
