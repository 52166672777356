import styled from 'styled-components'
import { PropsWithChildren } from 'react'

const TooltipStyles = styled.label`
  flex-wrap: nowrap;
  font-size: 0rem;
  position: centered;
`
type ToolTipProps = PropsWithChildren<{ message: string }>

export const Tooltip = ({ children, message }: ToolTipProps): JSX.Element => {
  return (
    <TooltipStyles title={`${message}`} area-label={message}>
      {children}
    </TooltipStyles>
  )
}
