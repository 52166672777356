import TimeAgo from 'timeago-react'
import * as timeago from 'timeago.js'

import en_short from 'timeago.js/lib/lang/en_short'

const shortLocale = 'en_short'
timeago.register(shortLocale, en_short)

import { Hint } from './Hint'

interface RelativeTimeProps {
  time: Date | string
}

export const RelativeTime: React.FC<RelativeTimeProps> = (
  props
): JSX.Element => {
  const time =
    typeof props.time === 'string' ? new Date(props.time) : props.time
  return (
    <>
      <TimeAgo datetime={time} locale={shortLocale} live={false} />{' '}
      <Hint message={time.toString()} />
    </>
  )
}
