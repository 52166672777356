import styled from 'styled-components'

export const TopLevelGrid = styled.main`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px max-content 1fr 16px 100px;
  grid-template-areas:
    'topnav'
    'tabnav'
    'main '
    'footgap'
    'footer';
  grid-gap: 8px;
  height: 100%;
  width: 100%;
`
